<template>
	<modal class="NoxModalVerification" name="NoxModalVerification" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'welcomeVerification'" v-html="$store.getters.getLanguageText('5.10', 0)"></span>
					<span v-else-if="noxType == 'isGreyVerification'" v-html="$store.getters.getLanguageText('5.10', 0)"></span>
					<span v-else-if="noxType == 'isGreyEditUsername'" v-html="$store.getters.getLanguageText('5.10', 5)"></span>
					<span v-else-if="noxType == 'approveVerification'" v-html="$store.getters.getLanguageText('5.10', 2)"></span>
					<span v-else-if="noxType == 'rejectVerification'" v-html="$store.getters.getLanguageText('5.10', 2)"></span>
					<span v-else-if="noxType == 'cancelVerification'" v-html="$store.getters.getLanguageText('5.10', 2)"></span>
					<span v-else-if="noxType == 'editCountryByUser'" v-html="$store.getters.getLanguageText('5.10', 7)"></span>
					<span v-else-if="noxType == 'editCountryByAdmin'">Изменение страны пользователя</span>
					<span v-else-if="noxType == 'banVerification'">Бан пользователя</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'welcomeVerification'" v-html="$store.getters.getLanguageText('5.10', 1)"></div>
					<div v-else-if="noxType == 'isGreyVerification'" v-html="$store.getters.getLanguageText('5.10', 4, { name: $store.state.noxSystemSettings['package_name_x' + noxPackage] })"></div>
					<div v-else-if="noxType == 'isGreyEditUsername'" v-html="$store.getters.getLanguageText('5.10', 6)"></div>
					<div v-else-if="noxType == 'approveVerification'">Вы действительно хотите одобрить документ?</div>
					<div v-else-if="['rejectVerification', 'banVerification'].includes(noxType)">
						<p v-if="noxType == 'rejectVerification'">Вы действительно хотите отклонить документ?</p>
						<p v-else-if="noxType == 'banVerification'">Вы действительно хотите забанить пользователя?</p>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Комментарий:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxComment" placeholder="краткий комментарий"></textarea>
								<div v-html="noxAlertComment"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'cancelVerification'" v-html="$store.getters.getLanguageText('5.10', 3)"></div>
					<div v-else-if="['editCountryByUser', 'editCountryByAdmin'].includes(noxType)">
						<p v-if="noxType == 'editCountryByUser'" v-html="$store.getters.getLanguageText('5.10', 8)"></p>
						<p v-else-if="noxType == 'editCountryByAdmin'">Вы действительно хотите изменить страну пользователя?</p>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.10', 9)"></div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxCountry" :placeholder="$store.getters.getLanguageText('5.10', 10)" :options="$parent.$parent.$parent.getSelectOptions(1)"></v-select>
								<div v-html="noxAlertCountry"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="follow" v-if="noxType == 'welcomeVerification'" v-html="$store.getters.getLanguageText('1.1', 25)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'isGreyVerification'" v-html="$store.getters.getLanguageText('1.1', 88, { name: $store.state.noxSystemSettings['package_name_x' + noxPackage] })"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'isGreyEditUsername'" v-html="$store.getters.getLanguageText('1.1', 89)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'approveVerification'" v-html="$store.getters.getLanguageText('1.1', 42)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'rejectVerification'" v-html="$store.getters.getLanguageText('1.1', 41)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'cancelVerification'" v-html="$store.getters.getLanguageText('1.1', 9)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'banVerification'" v-html="$store.getters.getLanguageText('1.1', 40)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editCountryByUser'" v-html="$store.getters.getLanguageText('1.1', 64)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editCountryByAdmin'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertComment: '',
			noxAlertCountry: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxPackage: 0,
			noxComment: '',
			noxCountry: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxPackage = event.params.package;
				this.noxComment = '';
				this.noxCountry = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editCountryByUser') {
					for (var i in this.$parent.$parent.$parent.getSelectOptions(1)) {
						if (this.$parent.$parent.$parent.getSelectOptions(1)[i].code == this.$store.state.noxAccountData.country) {
							this.noxCountry = this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
						}
					}
				}
				else if (this.noxType == 'editCountryByAdmin') {
					for (var j in this.$parent.$parent.$parent.getSelectOptions(1)) {
						if (this.$parent.$parent.$parent.getSelectOptions(1)[j].code == this.$parent.noxVerificationsData[this.noxIndex].country) {
							this.noxCountry = this.$parent.$parent.$parent.getSelectOptions(1)[j]; break;
						}
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertComment = '';
				this.noxAlertCountry = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 9); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 10); }
				else if (i == 3) { this.noxTemp = 'Необходимо указать комментарий.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный комментарий.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать id пользователя.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный id пользователя.'; }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 12); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 13); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 11); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 4); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 5); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 6); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 7); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 8); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 14); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 15); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'isGreyVerification') {
						config.url = '/v2/account/users/is_grey/reset';
						config.method = 'patch';
					}
					else if (_this.noxType == 'isGreyEditUsername') {
						config.url = '/v2/account/users/is_grey/fullname/reset';
						config.data = { username: _this.$parent.noxSettings1.username_new };
						config.method = 'patch';
					}
					else if (_this.noxType == 'approveVerification') {
						config.url = '/v2/admin/account/verification/approve';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'rejectVerification') {
						if (!_this.noxComment) {
							_this.noxAlertComment = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxComment)) {
							_this.noxAlertComment = _this.getError(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/verification/reject';
							config.data = { id: _this.noxId, comment: _this.noxComment };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'cancelVerification') {
						config.url = '/v2/account/verification/cancel';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'banVerification') {
						if (!_this.noxComment) {
							_this.noxAlertComment = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxComment)) {
							_this.noxAlertComment = _this.getError(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/verification/ban';
							config.data = { id: _this.noxId, comment: _this.noxComment };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'editCountryByUser') {
						if (!_this.noxCountry) {
							_this.noxAlertCountry = _this.getError(7);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/users/country';
							config.data = { country: _this.noxCountry.code };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'editCountryByAdmin') {
						if (!_this.noxCountry) {
							_this.noxAlertCountry = _this.getError(7);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/users/country';
							config.data = { user_id: _this.$parent.noxVerificationsData[_this.noxIndex].user_id, country: _this.noxCountry.code };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'isGreyVerification') {
									_this.$store.state.noxAccountData.is_grey = 0;
									_this.$store.state.noxAccountData.is_verification = 0;
									_this.$router.push({ path: '/account/verification' });
								}
								else if (_this.noxType == 'isGreyEditUsername') {
									_this.$parent.noxSettings1.username_old = _this.$parent.noxSettings1.username_new;
									_this.$store.state.noxAccountData.username = _this.$parent.noxSettings1.username_new;
									_this.$store.state.noxAccountData.fullname = '';
									_this.$store.state.noxAccountData.is_grey = 3;
									_this.$store.state.noxAccountData.is_verification = 0;
									_this.$parent.editSettings1('save', 'username');
								}
								else if (['approveVerification', 'rejectVerification', 'cancelVerification', 'banVerification'].includes(_this.noxType)) {
									_this.$parent.noxVerificationsData[_this.noxIndex].image_1 = '';
									_this.$parent.noxVerificationsData[_this.noxIndex].image_2 = '';
									_this.$parent.noxVerificationsData[_this.noxIndex].comment = _this.noxComment;
									_this.$parent.noxVerificationsData[_this.noxIndex].status = _this.noxType == 'approveVerification' ? 1 : (_this.noxType == 'rejectVerification' ? 2 : (_this.noxType == 'cancelVerification' ? 3 : 4));
									_this.$parent.noxVerificationsData[_this.noxIndex].updated_at = new Date().getTime() / 1000;
									_this.$parent.noxIsVerificationAdd = _this.noxType == 'cancelVerification' ? true : false;
								}
								else if (_this.noxType == 'editCountryByUser') {
									_this.$parent.$parent.$parent.initAccountData(data.data);
									_this.$store.state.noxSystemSettings['vat_value'] = _this.$parent.$parent.$parent.getVatByCountry();
									if (_this.$store.state.noxAccountData.is_verification) { _this.$router.push({ path: '/account' }); }
									else { setTimeout(function() { _this.$parent.$refs.cropImageForVerification.click(); }, 0); }
								}
								else if (_this.noxType == 'editCountryByAdmin') {
									_this.$parent.noxVerificationsData[_this.noxIndex].country = _this.noxCountry.code;
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'COMMENT_IS_EMPTY') { _this.noxAlertComment = _this.getError(3); }
									else if (data.response.data.error == 'COMMENT_NOT_VALID') { _this.noxAlertComment = _this.getError(4); }
									else if (data.response.data.error == 'USER_ID_IS_EMPTY') { _this.noxAlert = _this.getError(5); }
									else if (data.response.data.error == 'USER_ID_NOT_VALID') { _this.noxAlert = _this.getError(6); }
									else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.noxAlertCountry = _this.getError(7); }
									else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.noxAlertCountry = _this.getError(8); }
									else if (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.noxAlert = _this.getError(15); }
									else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.noxAlert = _this.getError(16); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(9); }
								}
								else if (data.response.status == 409) {
									if      (data.response.data.error == 'ALREADY_ADDED') { _this.noxAlert = _this.getError(10); }
									else if (data.response.data.error == 'ALREADY_APPROVED') { _this.noxAlert = _this.getError(11); }
									else if (data.response.data.error == 'ALREADY_REJECTED') { _this.noxAlert = _this.getError(12); }
									else if (data.response.data.error == 'ALREADY_CANCELED') { _this.noxAlert = _this.getError(13); }
									else if (data.response.data.error == 'ALREADY_BANNED') { _this.noxAlert = _this.getError(14); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							if (_this.noxType == 'editCountryByUser') {
								if (!_this.$store.state.noxAccountData.is_verification) { return false; }
							}
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			follow: function() {
				this.close();
				if (this.$route.path !== '/account/verification') {
					this.$router.push({ path: '/account/verification' });
				}
			},
			close: function() {
				this.$modal.hide('NoxModalVerification');
			}
		}
	}
</script>
